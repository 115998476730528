import { Template, TemplateConfig } from './type';

export class DefaultTemplate implements Template {
  private _text: string;
  private _indent: number;
  private _indentSize: number;
  private _lineSize: number;

  constructor({ indentSize = 2, lineSize = 48 }: TemplateConfig = {}) {
    this._text = '';
    this._indent = 0;
    this._indentSize = indentSize;
    this._lineSize = lineSize;
  }

  text(): string {
    return this._text;
  }

  indent(): this {
    this._indent += this._indentSize;

    return this;
  }

  unindent(): this {
    this._indent -= this._indentSize;

    return this;
  }

  empty(): this {
    this._text += '\n';

    return this;
  }

  start([text]: string[]): this {
    this._text += `${this._indentSpaces}${text}\n`;

    return this;
  }

  ifExist(texts: string[]) {
    const [text] = texts;
    if (text.trim()) {
      this._text += `${this._indentSpaces}${text}\n`;
    }

    return this;
  }

  end([text]: string[]): this {
    const spaceBefore = this._spaces(this._lineSize - this._indent - text.length);
    this._text += `${this._indentSpaces}${spaceBefore}${text}\n`;

    return this;
  }

  center([text]: string[]): this {
    const spaceSizeTotal = this._lineSize - this._indent - text.length;
    const spaceBefore = this._spaces(spaceSizeTotal / 2);
    this._text += `${this._indentSpaces}${spaceBefore}${text}${spaceBefore}\n`;

    return this;
  }

  spaceBetween(texts: string[]): this {
    const spaceSizeTotal = texts.reduce((acc, message) => acc - message.length, this._lineSize - this._indent);
    const spaceSizeBetween = spaceSizeTotal / (texts.length - 1);

    this._text += `${this._indentSpaces}${texts.join(this._spaces(spaceSizeBetween))}\n`;

    return this;
  }

  spaceAround(texts: string[]): this {
    const spaceSizeTotal = texts.reduce((acc, message) => acc - message.length, this._lineSize - this._indent);
    const spaceSizeBetween = spaceSizeTotal / texts.length;
    const spaceBefore = this._spaces(spaceSizeBetween / 2);

    this._text += `${this._indentSpaces}${spaceBefore}${texts.join(this._spaces(spaceSizeBetween))}${spaceBefore}\n`;

    return this;
  }

  spaceEvenly(texts: string[]): this {
    const spaceSizeTotal = texts.reduce((acc, message) => acc - message.length, this._lineSize - this._indent);
    const spaceSizeBetween = spaceSizeTotal / (texts.length + 1);
    const spaceBetween = this._spaces(spaceSizeBetween);

    this._text += `${this._indentSpaces}${spaceBetween}${texts.join(this._spaces(spaceSizeBetween))}${spaceBetween}\n`;

    return this;
  }

  private get _indentSpaces(): string {
    return ' '.repeat(this._indent);
  }

  private _spaces(size: number): string {
    return ' '.repeat(Math.max(size, 1));
  }
}
