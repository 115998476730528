import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { SettingsService } from '../settings';
import { Settings, Widget } from '../shared';

@Injectable()
export class WidgetsHttpService {
  constructor(private _settings: SettingsService) {}

  injectAttributes(widgets: Widget<any>[], pageId: string): Observable<Widget<any>[]> {
    return this._settings.all.pipe(
      first(),
      map(settings => {
        return widgets.map(({ attributes, type, ...rest }) => ({
          pageId,
          ...rest,
          type: this._renameComponent(type),
          attributes: this._addAttributes(type, { ...attributes }, pageId, settings, rest),
        }));
      }),
    );
  }

  private _addAttributes(type: string, attributes: any, pageId: string, settings: Settings, rest: any): any {
    attributes.mainColor = settings.colors.primary;
    attributes.secondColor = settings.colors.secondary;
    attributes.textColor = settings.colors.primaryForeground;

    if (!attributes.pop) {
      attributes.pop = {};
    }

    attributes.pop.validationStatus = 'verified';
    attributes.pop.readOnlyDisplayMsg = settings.pop.readOnlyDisplayMsg;

    if (['product_catalog', 'cart', 'product_teaser'].includes(type)) {
      const defaultCurrency = {
        code: 'EUR',
        symbol: '€',
      };

      attributes.currency = settings.currency || defaultCurrency;
      attributes.disableAditionsAfterOrder = settings.behaviors && settings.behaviors.disableAditionsAfterOrder;
      attributes.phone = settings.phone;
    }

    if (type === 'contact_info') {
      attributes.page = { phone: settings.phone };
    }

    if (['action_call', 'document_viewer'].includes(type)) {
      attributes.pageId = pageId;
      attributes.id = rest.id;
    }

    return attributes;
  }

  /**
   * @deprecated
   * @description need to rename widgets type in base date
   */
  private _renameComponent(name: string): string {
    const widgetComponents = {
      wifi: 'WidgetWifiComponent',
      welcome: 'WidgetWelcomeComponent',
      video: 'WidgetVideoComponent',
      twitter_profile: 'WidgetTwitterProfileComponent',
      twitter_htag: 'WidgetTwitterHtagComponent',
      twitter_tweet: 'WidgetTweetComponent',
      title: 'WidgetTitleComponent',
      text: 'WidgetTextComponent',
      shop_teaser: 'WidgetShopTeaserComponent',
      share: 'WidgetShareComponent',
      review: 'WidgetReviewComponent',
      program: 'WidgetProgramComponent',
      profile: 'WidgetProfileComponent',
      product_teaser: 'WidgetProductTeaserComponent',
      product_catalog: 'WidgetProductCatalogComponent',
      product_catalog_v2: 'ShopComponent',
      facebook_post: 'WidgetPostComponent',
      poll: 'WidgetPollComponent',
      map: 'MapComponent',
      link: 'WidgetLinkComponent',
      image: 'WidgetImageComponent',
      gallery: 'WidgetGalleryComponent',
      follow: 'WidgetFollowComponent',
      feedback: 'WidgetFeedbackComponent',
      documents: 'WidgetDocumentsComponent',
      document_viewer: 'WidgetDocumentViewerComponent',
      custom: 'WidgetCustomComponent',
      contact: 'WidgetContactComponent',
      cart: 'CartComponent',
      carousel: 'WidgetCarouselComponent',
      buttons: 'WidgetButtonsComponent',
      ask_question: 'WidgetAskQuestionComponent',
      action_call: 'WidgetActionCallComponent',
      action_bar: 'WidgetActionBarComponent',
      contact_info: 'WidgetContactInfoComponent',
      qr_code_reader: 'QrCodeComponent',
      loyalty_share: 'LoyaltyShareComponent',
    };

    return widgetComponents[name] || name;
  }
}
