import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';

import { EPosError, EPosErrorCodes, EPosErrorOptions } from './e-pos.error-handler';
import { Epson } from './epos-2.17.0.types';
import { Job, JobData } from './type';

type EPosServiceStatesKeys =
  | 'PRINTER_CONNECTION_ERROR'
  | 'DEVICE_CREATION_ERROR'
  | 'DEVICE_CREATION_SUCCESS'
  | 'PRINTING_ERROR'
  | 'PRINTING'
  | 'PRINTING_SUCCESS';

export interface EPosServiceStatus {
  ip: string;
  isConnected: boolean;
  isPrinting: boolean;
  error: EPosError;
}

@Injectable()
export class EPosService {
  private readonly DEBUG_MODE = false;

  private readonly FALLBACK_PRINTER_STATUS: EPosServiceStatus = {
    ip: '',
    error: null,
    isConnected: false,
    isPrinting: false,
  };

  private readonly EPosServiceStates: {
    [key in EPosServiceStatesKeys]: (
      options?: Partial<EPosServiceStatus> & { sdkError?: unknown },
    ) => EPosServiceStatus;
  } = {
    PRINTER_CONNECTION_ERROR: ({ ip, sdkError }) => ({
      ip,
      isConnected: false,
      isPrinting: false,
      error: this.ePosError(EPosErrorCodes.CONNECTION, { sdkError }),
    }),
    DEVICE_CREATION_ERROR: ({ ip, sdkError }) => ({
      ip,
      isConnected: false,
      isPrinting: false,
      error: this.ePosError(EPosErrorCodes.DEVICE, { sdkError }),
    }),
    DEVICE_CREATION_SUCCESS: ({ ip }) => ({
      ip,
      isConnected: true,
      isPrinting: false,
      error: null,
    }),
    PRINTING_ERROR: ({ sdkError }) => ({
      ip: this.printerIp,
      isConnected: false,
      isPrinting: false,
      error: this.ePosError(EPosErrorCodes.PRINTING, { sdkError, jobs: this._jobs }),
    }),
    PRINTING: () => ({
      ip: this.printerIp,
      isConnected: true,
      isPrinting: true,
      error: null,
    }),
    PRINTING_SUCCESS: () => ({
      ip: this.printerIp,
      error: null,
      isPrinting: false,
      isConnected: true,
    }),
  };

  private _printer: Epson['ePOSDevice'];
  private _device: any;

  private _jobs: Map<string, Job>;
  private _waitingJob: boolean;

  public printerStatus = new BehaviorSubject<EPosServiceStatus>(this.FALLBACK_PRINTER_STATUS);

  constructor() {
    this._printer = new window.epson.ePOSDevice();

    this._jobs = new Map();
    this._waitingJob = false;

    if (this.DEBUG_MODE) {
      this.printerStatus.subscribe(status => {
        console.log('*DEBUG MODE** PRINTER STATUS CHANGE: ', status);
      });
    }
  }

  disconnect(): void {
    this._printer.disconnect();
  }

  connect(ip: string, port?: string | number): Observable<this> {
    return new Observable((observer: Observer<this>) => {
      const protocol = window.location.protocol;
      port = port ?? (/^(https:)/.exec(protocol) ? this._printer.IFPORT_EPOSDEVICE_S : this._printer.IFPORT_EPOSDEVICE);

      this._printer.connect(ip, port, (connect: string) => {
        if (connect === 'OK' || connect === 'SSL_CONNECT_OK') {
          this._createDevice(
            () => {
              this.updateServiceStatus(this.EPosServiceStates.DEVICE_CREATION_SUCCESS({ ip }));
              observer.next(this);
            },
            err => {
              this.updateServiceStatus(this.EPosServiceStates.DEVICE_CREATION_ERROR({ ip, sdkError: err }));
              observer.error(err);
            },
          );
        } else {
          const status: EPosServiceStatus = this.EPosServiceStates.PRINTER_CONNECTION_ERROR({ ip });

          this.updateServiceStatus(status);
          observer.error(status);
        }
      });
    });
  }

  print(id: string, data: JobData): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this._jobs.set(id, { ...data, observer });

      this._printJob();
    });
  }

  private _createDevice(callBack: () => void, callBackErr: (err: string) => void): void {
    const deviceType = this._printer.DEVICE_TYPE_PRINTER;
    const deviceId = 'local_printer';

    this._printer.createDevice(
      deviceId,
      deviceType,
      { crypto: false, buffer: false },
      (device: any | null, code: string) => {
        if (code === 'OK') {
          this._device = device;
          callBack();
        } else {
          callBackErr(code);
        }
      },
    );
  }

  private _printJob(): void {
    if (
      /**
       * check if exist jobs
       */
      !this._jobs.size ||
      /**
       * waiting to finish precedent job
       */
      this._waitingJob
    )
      return;

    this._waitingJob = true;
    this.updateServiceStatus(this.EPosServiceStates.PRINTING());

    const jobId = this._jobs.keys().next().value as string;
    const job = this._jobs.get(jobId);

    if ('message' in job) {
      this._device.addText(`${job.message}\n`);
      this._device.addCut();
    } else {
      job.render(this._device);
    }

    this._device.onreceive = () => {
      this._jobs.delete(jobId);
      job.observer.next(jobId);
      job.observer.complete();
      /**
       * print next job
       */
      this.printerStatus.next({ ...this.currentPrinterStatus });
      this._waitingJob = false;
      this.updateServiceStatus(this.EPosServiceStates.PRINTING_SUCCESS());

      this._printJob();
    };
    // eslint-disable-next-line

    this._device.onerror = (err: any) => {
      this.updateServiceStatus(this.EPosServiceStates.PRINTING_ERROR({ sdkError: err }));
    };

    this._device.send(jobId);
  }

  private ePosError(code: EPosErrorCodes, { sdkError, jobs }: Omit<EPosErrorOptions, 'service'>): EPosError {
    return new EPosError(code, { sdkError, jobs, service: this });
  }

  private updateServiceStatus(status: EPosServiceStatus): void {
    this.printerStatus.next(status);
  }

  private get printerIp(): string {
    return this.currentPrinterStatus.ip;
  }

  private get currentPrinterStatus(): EPosServiceStatus {
    return this.printerStatus.value;
  }
}
