<div *ngIf="isValid; else elseBlock" class="widget-ask-question">
  <ul class="my-questions" *ngIf="!attributes.hideQuestions">
    <li *ngFor="let question of myQuestions" class="question-item">
      <div class="question-detail" *ngIf="question.userName">
        <span>
          {{ labels.QUESTION || 'ASK_QUESTION.QUESTION' | translate }}
          {{ labels.BY || 'ASK_QUESTION.BY' | translate }}
          <span [style.color]="attributes.mainColor">{{ question.userName }}</span>
        </span>
      </div>
      <span class="question">{{ question.question }}</span>
    </li>
  </ul>
  <h2 class="title">{{ labels.TITLE || 'ASK_QUESTION.TITLE' | translate }}</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <div class="field">
      <label for="question">{{ labels.QUESTION || 'ASK_QUESTION.QUESTION' | translate }}</label>
      <div *ngIf="showErrors" class="error">
        {{ labels.QUESTION_REQUIRED || 'ASK_QUESTION.QUESTION_REQUIRED' | translate }}
      </div>
      <textarea id="question" formControlName="question" resize="false" maxlength="200"></textarea>
    </div>
    <div class="field">
      <label for="ask-name">{{ labels.NAME || 'ASK_QUESTION.NAME' | translate }}</label>
      <input type="text" id="ask-name" formControlName="name" maxlength="50" />
    </div>
    <button type="submit" [style.borderColor]="buttonColor" [style.color]="buttonColor">
      <span>
        {{ labels.SUBMIT_BUTTON || 'ASK_QUESTION.SUBMIT_BUTTON' | translate }}
        <pop-widget-spinner
          *ngIf="isLoadingLocal"
          [color]="attributes.mainColor"
          [width]="16"
          [height]="16"
        ></pop-widget-spinner>
      </span>
    </button>
  </form>
</div>
<ng-template #elseBlock>
  <div *ngIf="attributes?.isEditMode" class="widget-ask-question">{{ noValidMessage }}</div>
</ng-template>
