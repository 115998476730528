import { EPosService } from '@bend/e-pos';

import { Job } from './type';

export enum EPosErrorCodes {
  CONNECTION = 'ERROR WHILE PRINTER CONNECTION',
  PRINTING = 'ERROR WHILE PRINTING',
  DEVICE = 'ERROR WHILE CREATION DEVICE',
}

export interface EPosErrorOptions {
  sdkError?: unknown;
  jobs?: Map<string, Job>;
  service: EPosService;
}

export class EPosError {
  internalError: EPosErrorCodes;
  sdkError: unknown;

  service: EPosService;

  jobs?: Map<string, Job>;

  constructor(code: EPosErrorCodes, options: EPosErrorOptions) {
    this.internalError = code;
    this.sdkError = options.sdkError;
    this.jobs = options.jobs ?? null;
    this.service = options.service;

    console.log('EPosError: ', this);
  }
}
